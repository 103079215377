import React from 'react';
import './App.css';

function App() {
  return (
    <main className="App-main">
      <div className="centered">
        <div>
          <span className="title--largest">The Driven Dev</span>
          <br />
          <span className="title--large">Launching Early 2020</span>
        </div>
      </div>
    </main>
  );
}

export default App;
